import React, { useEffect, useState } from 'react';
import {Area, AreaChart, ResponsiveContainer, Tooltip, XAxis} from 'recharts';
import {Typography} from "@mui/material";
import { useTranslation } from 'react-i18next';

const SiteVisitsGraph = ({survey}) => {
    const {t} = useTranslation();
    const [siteSurveys,setSiteSurveys] = useState([
        {mes: '', primera: 0, segunda: 0},
    ]);

    useEffect(()=>{
        if(survey && survey.months){
            setSiteSurveys(survey.months)
        }
    })

    return (
        <React.Fragment>
            <Typography variant={"h5"}>Nº de Encuestas</Typography>
            <ResponsiveContainer width="100%" height={120}>
                <AreaChart data={siteSurveys} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                    <Tooltip
                        labelStyle={{color: 'black'}}
                        labelFormatter={function (value) {
                            return `Mes: ${t('general.months.'+value)}`;
                        }}
                        cursor={false}
                    />
                    <XAxis dataKey="mes" hide/>
                    <Area type="monotone" dataKey="primera" name="1ª encuesta" stackId="1" stroke="#0795F4" fillOpacity={1}
                          fill="#0795F4"/>
                    <Area type="monotone" dataKey="segunda" name="2ª encuesta" stackId="1" stroke="#9BE7FD" fillOpacity={1} fill="#9BE7FD"/>
                </AreaChart>
            </ResponsiveContainer>
        </React.Fragment>

    );
};

export default SiteVisitsGraph;
