import React from "react";
import ListIcon from '@mui/icons-material/List';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { PersonPinCircleRounded } from "@mui/icons-material";

const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
        children: [
            {
                uri: "/dashboards/survey",
                label: 'sidebar.menuItem.listing',
                type: "nav-item",
                icon: <ListAltOutlinedIcon sx={{fontSize: 20}}/>
            },
        ]
    },

   /* {
        label: 'sidebar.menu.authPages',
        type: "section",
        children: [
            {
                label: 'sidebar.menu.login',
                type: "collapsible",
                icon: <LoginIcon sx={{fontSize: 20}}/>,
                children: [
                    {
                        uri: "/auth-pages/login-1",
                        label: 'sidebar.menuItem.login1',
                        type: "nav-item",
                        target: "_blank"
                    },
                    {
                        uri: "/auth-pages/login-2",
                        label: 'sidebar.menuItem.login2',
                        type: "nav-item",
                        target: "_blank",
                    }
                ]
            },
            {
                label: 'sidebar.menu.signup',
                type: "collapsible",
                icon: <PersonAddAltIcon sx={{fontSize: 20}}/>,
                children: [
                    {
                        uri: "/auth-pages/signup-1",
                        label: 'sidebar.menuItem.signup1',
                        type: "nav-item",
                        target: "_blank"
                    },
                    {
                        uri: "/auth-pages/signup-2",
                        label: 'sidebar.menuItem.signup2',
                        type: "nav-item",
                        target: "_blank"
                    }
                ]
            },
            {
                uri: "/auth-pages/forgot-password",
                label: 'sidebar.menuItem.forgetPassword',
                type: "nav-item",
                icon: <PasswordOutlinedIcon sx={{fontSize: 20}}/>,
                target: "_blank"
            },
            {
                uri: "/auth-pages/reset-password",
                label: 'sidebar.menuItem.resetPassword',
                type: "nav-item",
                icon: <LockResetIcon sx={{fontSize: 20}}/>,
                target: "_blank"
            }
        ]
    },
    {
        label: 'sidebar.menu.user',
        type: "section",
        children: [
            {
                uri: "/user/profile",
                label: 'sidebar.menuItem.profile',
                type: "nav-item",
                icon: <AccountBoxOutlinedIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/user/social-wall",
                label: 'sidebar.menuItem.socialWall',
                type: "nav-item",
                icon: <StreamOutlinedIcon sx={{fontSize: 20}}/>
            }
        ]
    },*/
    {
        label: 'sidebar.menu.listView',
        type: "section",
        children: [
           /* {

                uri: "/list-views/projects",
                label: 'sidebar.menuItem.projects',
                type: "nav-item",
                icon: <ViewListOutlinedIcon sx={{fontSize: 20}}/>
            },*/

            {
                uri: "/list-views/users",
                label: 'sidebar.menuItem.users',
                type: "nav-item",
                icon: <PersonPinCircleRounded sx={{fontSize: 20}}/>
            },

            {
                uri: "/list-views/answers",
                label: 'sidebar.menuItem.answers',
                type: "nav-item",
                icon: <ListIcon sx={{fontSize: 20}}/>
            }
        ]
    },

];

export default menus;
