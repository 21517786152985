import React from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemIcon, ListItemText, Slide, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { LoadingButton } from '@mui/lab';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { getDateFormat } from 'app/utils/appHelpers';
import surveyServices from 'app/services/survey-services';
import { now } from 'moment';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="down" ref={ref} {...props} />;
});

const GeneralInfo = ({ survey, setData}) => {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setError('');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSurvey = () => {
        setLoading(true);

        surveyServices.closeSurvey('').then(res => {
            console.log(res);
            if(res.success && res.survey){
                let newData = survey;
                newData.dateEnd = res.survey.dateEnd;
                setData(newData);
                setOpen(false);
                setError('');
            }else{
                setError(res.message);
            }
            
        })
        .catch((error) => {
            setError('Ha habido un error cerrando la encuesta');
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const generalInfo = [
        /*{
            id: 1,
            title: "5 Unread Messages",
            icon: <MessageOutlined fontSize={'small'}/>
        },
        {
            id: 2,
            title: "2 Pending Invitations",
            icon: <Mail fontSize={'small'}/>
        },
        {
            id: 3,
            title: "7 Due Tasks",
            icon: <TaskAltOutlinedIcon fontSize={'small'}/>
        },
        {
            id: 4,
            title: "3 Other notifications",
            icon: <NotificationsActiveRoundedIcon fontSize={'small'}/>
        },*/
    ]


    return (
        <React.Fragment>
            <Typography variant={"h5"}></Typography>
            {survey && !survey?.dateEnd && (<Button variant="contained" onClick={handleClickOpen}>Cerrar Encuesta</Button>)}
            <List>
                <ListItemButton
                    key={1}
                    sx={{
                        px: 0,
                        py: .25,

                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }}
                >
                    <ListItemIcon sx={{ minWidth: 32 }}><DateRangeIcon fontSize={'small'} /></ListItemIcon>
                    <ListItemText primary={<Typography>{'Abierta desde: ' + getDateFormat(survey.dateStart)}</Typography>} />
                </ListItemButton>
                {survey && survey?.dateEnd && (
                <ListItemButton
                    key={2}
                    sx={{
                        px: 0,
                        py: .25,

                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }}
                >
                    <ListItemIcon sx={{ minWidth: 32 }}><DateRangeIcon fontSize={'small'} /></ListItemIcon>
                    <ListItemText primary={<Typography>{'Cerrada: ' + getDateFormat(survey.dateEnd)}</Typography>} />
                </ListItemButton>
                )}
                {/*
                    
                    generalInfo.map((item, index) => (
                        <ListItemButton
                            key={index}
                            sx={{
                                px: 0,
                                py: .25,

                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            <ListItemIcon sx={{minWidth: 32}}>{item.icon}</ListItemIcon>
                            <ListItemText primary={<Typography>{item.title}</Typography>}/>
                        </ListItemButton>
                    ))*/
                }
            </List>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Cerrar encuesta?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Va a cerrar la encuesta. Una vez cerrada se procederá al envío de correos a los participantes.
                        Esta acción no es reversible ¿Desea continuar?
                    </DialogContentText>
                    {error && (
                        <Alert severity="error">{error}</Alert>
                    )}
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                    <LoadingButton variant="contained" loading={loading} onClick={handleCloseSurvey} autoFocus>
                        Continuar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default GeneralInfo;
