import React, { useEffect, useState } from 'react';
import {Alert, Snackbar, Typography} from "@mui/material";
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import surveyServices from 'app/services/survey-services';


const AnswerList = () => {
    const [data,setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const {t} = useTranslation();

    useEffect(()=>{
        surveyServices.getAnswers('?getData').then(res=> setData(res.data))
        
    },[])


    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    

    function safeEval(fn) {
        try { return fn(); }
        catch { return undefined; }
    }

    const options = {
    filterType: 'checkbox',
    selectToolbarPlacement: 'none',
    //selectableRows: 'multiple',
    señlectableRowsOnClick: true,
    displayRowCheckbox:false,
    selectableRowsHideCheckboxes: true,
    
    textLabels: {
        pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Filas:",
            displayRows: "de" // 1-10 of 30
        },
        toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Ver Columnas",
            filterTable: "Filtrar Tabla"
        },
        filter: {
            title: "FILTROS",
            reset: "Reiniciar",          
        },
        viewColumns: {
            title: "Mostrar Columnas"
        },
        selectedRows: {
            text: "fila(s) a eliminar",
            delete: "Eliminar"
        },
        body: {
            noMatch: 'No se han encontrado registros...',
        }
    },
    downloadOptions: { filename: "Respuestas.csv", separator: ";" ,utf8WithBom:true},
    onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
    };

    function getUserDataParsed(item){
            return[
                safeEval(()=> item.survey_question.survey_block.name),
                safeEval(()=> item.survey_question.title),
                safeEval(()=> item.text),
                item.correct ? t('general.yes') : t('general.no'),
                getSurveyDataParsed(safeEval(() => item.respondidas), safeEval(() => item.survey_question.survey_block.encuestas))
            ]
    }

    function getSurveyDataParsed(answered, total){
        let percent = total ? ` (${ ((Number(answered)/Number(total))*100).toFixed(0)}%)` : ' (0%)';
        return  answered +"/" +total + percent
    }

    const columns =  ["Bloque", "Pregunta" , "Respuesta","Correcta", "Ratio" ];
    const parsedData = data.map((item,i) => [
      ...getUserDataParsed(item),
    ]);


    return (
        <React.Fragment>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity="error" sx={{width: '100%'}}>
                        Ha habido un error cargando los datos
                    </Alert>
                </Snackbar>

            <Typography variant={'h2'} mb={3}>{t("sidebar.menuItem.answers")}</Typography>
            {
                /*users.map((user, index) => (
                    <UserItem user={user} key={index}/>
                ))*/
            }
            <>
            <MUIDataTable 
            title={t("sidebar.menuItem.answers")} 
            data={parsedData} 
            columns={columns} 
            options={options} 
            />
            </>
        </React.Fragment>
    );
};

export default AnswerList;
