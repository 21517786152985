import jwtAuthAxios from "./auth/jwtAuth";

const authServices = {};

authServices.getCurrentUser = async () => {
    const {data} = await jwtAuthAxios.get("/user");
    return data;
};

//loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
authServices.signIn = async (loginCreds) => {
    const {data} = await jwtAuthAxios.post('v1/login', loginCreds);
    return data;
};

authServices.logout = async (getParams = '') => {
    const {data} = await jwtAuthAxios.post("v1/logout"+ getParams);
    return data;
};

export default authServices;