import React from 'react';
import {Grid} from "@mui/material";

import WelcomeSurveySummary from '../../../shared/metrics/WelcomSurveySummary/WelcomeSurveySummary';

import ProjectCounterCard from "../../../shared/widgets/ProjectCounterCard";
import TasksCounterCard from "../../../shared/widgets/TasksCounterCard";
import TeamsCounterCard from "../../../shared/widgets/TeamsCounterCard";
import FilesCounterCard from "../../../shared/widgets/FilesCounterCard";

import TicketsStatus from "../../../shared/metrics/TicketsStatus";
import surveyServices from 'app/services/survey-services';
import CustomCardWidget from 'app/shared/widgets/CustomCardWidget';
import { getPercentFormat } from 'app/utils/appHelpers';



const SurveyDashboard = () => {

    const [data,setData] = React.useState([]);
    React.useEffect(()=>{
        let isMounted = true;
        
        (async () => {
            surveyServices.getActiveSurvey('?getData&getStats&getCounts').then(res=> {
                if(isMounted){
                    setData(res.data);
                }
            })
        })()
        return () => {isMounted = false};
    },[])


    const getDataCustomCard = (data,index)=>{
        let bgColor= index=== 0 ? '#6f42c1' : ( index % 2 ===0 ? '#23BCBA' : '#E73145')
        let title = index === 0 ? 'Todas' : index+"ª Enc";
        let text = "Total: "+ (data && data.stats && data.stats.length > index ? data.stats[index].encuestas : '...');
        let subtext = "Media aciertos: " + (data && data.stats && data.stats.length > index ? getPercentFormat(data.stats[index].media, data.stats[index].total) : '...');
        let description = "Desviación: " + (data && data.stats && data.stats.length > index ? getPercentFormat(data.stats[index].desviacion, data.stats[index].total) : '...');
    
        return (
            <Grid key={"customCardWidget-"+index} item xs={12} sm={6} lg={3}>
                <CustomCardWidget bgColor={bgColor} title={title} text={text} subtext={subtext} description={description} />
            </Grid>)
    }


    
    const getResultsComparison = (data, indexStart,indexEnd)=>{
        if(data && data.stats && data.stats.length > indexStart && data.stats.length > indexEnd){
            let bgColor= '#6f42c1'
            let title = "%";
            let text = ((Number(data.stats[indexEnd].media) / Number(data.stats[indexStart].media) -1) * 100).toFixed(0)+"%";
            let subtext = "Ratio de mejora";
            let description = "De "+indexStart+"ª encuesta a "+indexEnd+"ª";
            return (
                <Grid key={"customCardWidget-Comparison"} item xs={12} sm={6} lg={3}>
                <CustomCardWidget  bgColor={bgColor} title={title} text={text} subtext={subtext} description={description} />
                </Grid>
                /*<CustomCardWidget  title="%" text="24%" subtext="Ratio de mejora" description="De 1ª encuesta a 2ª"/>*/
            )
        }else{
            return (<></>)
        }
    }


    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <WelcomeSurveySummary data={data} setData={setData}/>
            </Grid>

            {data && data.stats && data.stats.map((d,index) => getDataCustomCard(data,index))}
            {data && data.stats && data.stats.length > 2 && getResultsComparison(data,1,2)}


            {/*
            <Grid item xs={12} sm={6} lg={3}>
                <TasksCounterCard/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <TeamsCounterCard/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <FilesCounterCard/>
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
                <TicketsStatus/>
            </Grid>
            */}
        </Grid>
    );
};

export default SurveyDashboard;
