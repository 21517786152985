import baseAxios from "axios";

const axios = baseAxios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept'      : 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }
});

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default axios;