import React from "react";
import { Navigate } from "react-router-dom";
import dashboardRoutes from "./dashboardRoutes";
import authRoutes from "./authRoutes";
import userRoutes from "./userRoutes";
import listViewRoutes from "./listViewRoutes";
import Login2 from "../pages/auth-pages/login2";
import Signup2 from "../pages/auth-pages/signup2";
import Page from "@jumbo/shared/Page";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Navigate to={"/dashboards/survey"} />
    },
    ...dashboardRoutes,
    ...authRoutes,
    ...userRoutes,
    ...listViewRoutes,
    
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: "/user/login",
        element: <Page component={Login2} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/user/signup",
        element: <Signup2/>
    },
];

const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};
