import React from "react";
import Page from "@jumbo/shared/Page";
import SurveyDashboard from "app/pages/dashboards/survey/SurveyDashboard";

const dashboardRoutes = [
    {
        path: "/dashboards/survey",
        element: <Page component={SurveyDashboard}/>,
    },
];

export default dashboardRoutes;
