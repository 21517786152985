import React from 'react';
import {LinearProgress, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import styled from "@mui/material/styles/styled";
import userServices from 'app/services/user-services';

const StyledLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: 6,
    borderRadius: 5,
    flex: 1
}));

const SiteAudienceInfo = () => {

    const [data,setData] = React.useState([
        {label: 'Masculino', value: 0, color: 'warning'},
        {label: 'Femenino', value: 0, color: 'secondary'},
        {label: 'Otros', value: 0, color: 'success'},
    ]);

    React.useEffect(()=>{
        (async () => {
            userServices.getVisitStats('?getData').then(res=> {
                let total = (res.data.men+res.data.women+res.data.other)
                let men = Number((res.data.men*100/total).toFixed(0));
                let women =  Number((res.data.women*100/total).toFixed(0));
                let other = 100 - women - men;
                setData([
                {label: 'Masculino', value: men, color: 'warning'},
                {label: 'Femenino', value: women, color: 'secondary'},
                {label: 'Otros', value: other, color: 'success'},
            ]); 
            })
        })()
    },[])

    return (
        <React.Fragment>
            <Typography variant={"h5"}>Encuestados</Typography>
            {
                data.map((item, index) => (
                    <React.Fragment key={index}>
                        <Typography variant={'body1'} color={'text.secondary'}>{item.label}</Typography>
                        <Div
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: .5
                            }}
                        >
                            <StyledLinearProgress
                                variant={"determinate"}
                                value={item.value}
                                color={item.color}
                            />
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                ml={1}
                            >{`${item.value}%`}</Typography>
                        </Div>
                    </React.Fragment>
                ))
            }
        </React.Fragment>
    );
};

export default SiteAudienceInfo;
