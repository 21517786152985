//import axios from "./config";
import axios from "./auth/jwtAuth";

const userServices = {};

userServices.getUsers = async (getParams = '') => {
    const {data} = await axios.get("v1/users"+ getParams);
    return data;
};

userServices.getVisitStats = async (getParams = '') => {
    const {data} = await axios.get("v1/user-stats"+ getParams);
    return data;
};



export default userServices;