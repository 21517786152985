
//import ProjectsList from "../pages/list-views/ProjectsList";
import UsersList from "../pages/list-views/UsersList";
import AnswerList from "../pages/list-views/AnswerList";
import Page from "@jumbo/shared/Page";

const listViewRoutes = [
    /*{
        path: "/list-views/projects",
        element: <Page component={ProjectsList}/>
    },*/
    {
        path: "/list-views/users",
        element: <Page component={UsersList}/>,
    },
    {
        path: "/list-views/answers",
        element: <Page component={AnswerList}/>,
    },
];
export default listViewRoutes;
