//import axios from "./config";
import axios from "./auth/jwtAuth";

const surveyServices = {};

surveyServices.getAnswers = async (getParams = '') => {
    const {data} = await axios.get("v1/surveyanswers"+ getParams);
    return data;
};

surveyServices.getActiveSurvey = async (getParams = '') => {
    const {data} = await axios.get("v1/survey-active"+ getParams);
    return data;
};

surveyServices.closeSurvey = async (getParams = '') => {
    const {data} = await axios.post("v1/survey-close"+ getParams, []);
    return data;
};

export default surveyServices;