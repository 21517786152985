import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import {SliderMarkLabel} from "@mui/material";
const CustomCardWidget = ({bgColor ='#6f42c1', 
                        image = getAssetPath(`${ASSET_IMAGES}/dashboard/projectIcon.svg`,"46x46"),
                        title = 'TITLE',
                        text = 'text',
                        subtext = 'subtext',
                        description='description',
                        }) => {
    return (
        <JumboCardQuick bgColor={bgColor}>
            <Div sx={{display: "flex", alignItems: 'center'}}>
                {/*<img alt={subtext} src={image}/>*/}
                <Div>
                    <Typography color={'common.white'} variant={"h2"} mb={.5} align='center'>{title}</Typography>
                </Div>
                
                
                <Div sx={{ml: 2, flex: 1}}>
                    <Typography color={'common.white'} variant={"h3"} mb={.5}>{text}</Typography>
                    <Typography color={'common.white'} variant={"h5"} mb={0.5}>{subtext}</Typography>
                    <Typography color={'common.white'} variant={"h7"} mb={0}>{description}</Typography>
                </Div>
            </Div>
        </JumboCardQuick>
    );
};

export default CustomCardWidget;
