import React, { useEffect, useState } from 'react';
import {Typography} from "@mui/material";
import userServices from 'app/services/user-services';
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import { getDateFormat } from 'app/utils/appHelpers';


const UsersList = () => {
    const [data,setData] = useState([]);
    const {t} = useTranslation();

    useEffect(()=>{
        userServices.getUsers('?getData').then(res=> setData(res.data))
    },[])
    

    function safeEval(fn) {
        try { return fn(); }
        catch { return undefined; }
    }

    const options = {
    filterType: 'checkbox',
    selectToolbarPlacement: 'none',
    //selectableRows: 'multiple',
    señlectableRowsOnClick: true,
    displayRowCheckbox:false,
    selectableRowsHideCheckboxes: true,
    
    textLabels: {
        pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Filas:",
            displayRows: "de" // 1-10 of 30
        },
        toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Ver Columnas",
            filterTable: "Filtrar Tabla"
        },
        filter: {
            title: "FILTROS",
            reset: "Reiniciar",          
        },
        viewColumns: {
            title: "Mostrar Columnas"
        },
        selectedRows: {
            text: "fila(s) a eliminar",
            delete: "Eliminar"
        },
        body: {
            noMatch: 'No se han encontrado registros...',
        },

    },
    downloadOptions: { filename: "Usuarios.csv", separator: ";" ,utf8WithBom:true},
    onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
    };

    function getUserDataParsed(item){
        if(item.userData){
            return[
                safeEval(()=> t('general.gender.'+item.userData.gender)),
                safeEval(()=> t('general.studies.'+item.userData.grades)),
                safeEval(()=> item.userData.age),
                safeEval(()=> t('general.experience.'+item.userData.experience))
            ]
        }
        return []
    }

    function getSurveyDataParsed(item){
        let result_1 = ((Number(item.right1) / (Number(item.right1) + Number(item.wrong1)))*100).toFixed(0);
        let result_2 = ((Number(item.right2) / (Number(item.right2) + Number(item.wrong2)))*100).toFixed(0);
        return [ isNaN(result_1) ?  '---' : result_1 +"%",
                 isNaN(result_2) ?  '---' : result_2 +"%",
                ]   
    }

    const columns =  ["Email", "Permite contactar" , "Fecha","Sexo", "Estudios","Edad","Experiencia", "Encuesta 1", "Encuesta 2" ];
    const parsedData = data.map((item,i) => [
        item.email, 
        item.allow ? t('general.yes') : t('general.no'), 
        getDateFormat(item.date),
        ...getUserDataParsed(item),
        ...getSurveyDataParsed(item),
    ]);


    return (
        <React.Fragment>
            <Typography variant={'h2'} mb={3}>{t("sidebar.menuItem.users")}</Typography>
            {
                /*users.map((user, index) => (
                    <UserItem user={user} key={index}/>
                ))*/
            }
            <>
            <MUIDataTable 
            title={t("sidebar.menuItem.users")} 
            data={parsedData} 
            columns={columns} 
            options={options} 
            />
            </>
        </React.Fragment>
    );
};

export default UsersList;
