import {USE_IMAGE_PLACEHOLDERS} from "./constants/paths";
import {format} from "date-fns"

export const getAssetPath = (url, size) => {
    if(USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }

    return url;
};

export const getDateFormat = (date) => {
    if(!date) return ''
    date = new Date(date);
    return format(date,'dd/MM/yyyy');
};

export const getPercentFormat = (value,total,decimals = 0) => {
    let dividend = Number(value);
    let divisor = Number(total);
    if(divisor === 0 || isNaN(divisor) || isNaN(dividend) ) return '0%'
    
    return ((dividend/divisor)*100).toFixed(decimals)+"%";
};